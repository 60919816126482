import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SizingDashboardService {
  constructor(private http: HttpClientService) {}

  fetchSizingDashboard(projectId: number) {
    return this.http
      .get(`${APICONSTANTS.PROJECT}/${projectId}/sizing-dashboard`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveSizingDashboard(body: any, projectId: number) {
    return this.http
      .put(`${APICONSTANTS.PROJECT}/${projectId}/sizing-dashboard`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  calculateDirectCost(body: any, projectId: number) {
    return this.http
      .put(
        `${APICONSTANTS.PROJECT}/${projectId}/sizing-dashboard/direct-cost/calculate`,
        body
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveAssetFundingSource(projectId: number, assetId: number, data: any) {
    return this.http
      .post(
        `${APICONSTANTS.PROJECT}/${projectId}/sizing-dashboard/funding-sources`,
        data
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
